<template>
  <!-- 应收会员余额页面 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-button class="itemBtn btnColor" @click="Urge">催款</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                placeholder="请输入内容"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.nickname"
                placeholder="请输入内容"
                clearable
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">审核起始日期:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.writeoffStart"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">审核结束日期:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.writeoffEnd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div
          class="item_left"
          v-if="hasPermiss('ysgl_yingShouHuiYuanYuEr:query')"
        >
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            sortable
            :sort-method="sortMethod"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
        <el-table-column fixed="right" width="80">
          <template slot-scope="scope">
            <div class="detailsBtns" @click="getDetails(scope.row)">
              账单详情
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>
    <!-- 上传编辑好的导出模板 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 收款确认 -->
    <div>
      <el-dialog
        title="收款确认"
        :visible.sync="show_writeOff"
        :modal-append-to-body="false"
        width="40%"
        center
        @close="closeDiawriteOff"
      >
        <div>
          <el-form
            label-position="right"
            label-width="80px"
            :model="formLabelAlign"
          >
            <el-form-item label="收款金额">
              <el-input v-model="formLabelAlign.amountCollected"></el-input>
            </el-form-item>
            <el-form-item label="资金账户">
              <el-select
                v-model="formLabelAlign.accountId"
                placeholder="请选择账户类型"
                @change="changeAccountId"
              >
                <el-option
                  v-for="(item, idx) in capitalAccountList"
                  :key="idx"
                  :label="item.showTitle"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="账户类型">
              <el-select
                v-model="formLabelAlign.accountStatus"
                placeholder="请选择账户类型"
              >
                <el-option
                  v-for="(item, idx) in account_status_list"
                  :key="idx"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="资金账户">
              <el-input v-model="formLabelAlign.accountId"></el-input>
            </el-form-item> -->
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="saveBtn" @click="saveWriteOff">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 查看详情 -->
    <div>
      <el-dialog
        title="账单详情"
        :visible.sync="show_Details"
        :modal-append-to-body="false"
        width="90%"
        center
        @close="closeDiaDetails"
      >
        <div>
          <div class="topTipsstyle" style="margin-bottom: 10px">
            <div style="margin-right: 20px">
              会员 : <span style="font-weight: 700">{{ mbName }}</span>
            </div>
            <div>
              会员余额: <span style="font-weight: 700">{{ memberMoney }}</span>
            </div>
          </div>
          <el-table
            ref="mytable"
            :data="BalanceDetailList"
            :row-class-name="tableRowClassName"
            highlight-current-row
            border
            :header-cell-style="{ background: '#f0f0f0' }"
          >
            <el-table-column
              fixed="left"
              label="序号"
              type="index"
              width="60"
            ></el-table-column>
            <el-table-column
              label="单据类型"
              prop="documentStatusShow"
              sortable
              min-width="100"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="单据编号"
              prop="documentNumber"
              sortable
              min-width="100"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="申请打包编号"
              prop="billpackNumber"
              sortable
              min-width="100"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="仓库名称"
              prop="storageName"
              sortable
              min-width="100"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="结算金额"
              prop="settlementAmount"
              sortable
              min-width="80"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="核销状态"
              prop="writeoffStatusShow"
              sortable
              min-width="80"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <!-- <el-table-column
              label="会员余额"
              prop="curAmount"
              sortable
              min-width="100"
              :show-overflow-tooltip="true"
            ></el-table-column> -->
            <el-table-column
              label="备注"
              prop="comment"
              sortable
              min-width="100"
              :show-overflow-tooltip="true"
            ></el-table-column>

            <el-table-column
              label="创建时间"
              prop="createTime"
              sortable
              min-width="110"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="审核时间"
              prop="approveTime"
              sortable
              min-width="110"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="审核人"
              prop="approverUserName"
              sortable
              min-width="80"
              :show-overflow-tooltip="true"
            ></el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <paging
            :pageNum="pageNum_d"
            :total="total_d"
            :sizeList="sizeList"
            :size="size_d"
            @handleSizeChange="handleSizeChange_d"
            @handleCurrentChange="handleCurrentChange_d"
          ></paging>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="saveBtn" @click="show_Details = false"
            >关 闭</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import mathjs from "@/assets/js/MathNumBer";
// import screenfull from "screenfull";

// import { axios_file } from "@/assets/js/http_axios";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      queryData: {
        writeoffEnd: null, //核销结束时间
        writeoffStart: null, //核销开始时间
        memberId: null, //会员号
        nickname: null, //会员名称
      },
      show_writeOff: false, //收款确认弹窗
      show_Details: false, //收款确认弹窗
      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      size: 50, //一页几条
      pageNum_d: 1, //页码
      total_d: 0,
      size_d: 20, //一页几条
      sizeList: [10, 20, 50, 100, 200],
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickname",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "总票数",
          field: "financeInCount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "应收金额",
          field: "settlementAmountAll",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员余额",
          field: "amount",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "BalanceSheet", //页面标识
      writeoffStatusList: [], //核销状态
      approveStatusList: [], //审核状态类别
      WarehouseList: [], //仓库下拉列表
      objectStatusList: [], //结算对象类型
      documentStatusList: [], //单据类型
      account_status_list: [], //资金账号类型
      capitalAccountList: [], //资金账号下拉列表
      ids: [], //选中的数据
      formLabelAlign: {
        amountCollected: "", //收款金额
        accountStatus: "", //账户类型
        accountId: "", //资金账户id
        ids: [], //应收计费id数组
      },
      changList: [], //当前选中的行数据
      BalanceDetailList: [],
      memberMoney: 0,
      mbName: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    // this.getGroupFindFieldSort(); //获取用户自定义表头
    this.myTableHeard = this.tabelHeadeTitle;

    // this.queryData.writeoffEnd = tools.getFlexDate(1); //今天的时间;
    this.queryData.writeoffEnd = tools.getCurrentDate(); //今天的时间;
    this.queryData.writeoffStart = tools.getFlexDate(-10); //30天前

    /* this.getStatusValList("finance_in_record_v1.approve_status"); //审核状态
    this.getStatusValList("finance_in_record_v1.object_status"); //结算对象类型
    this.getStatusValList("finance_in_record_v1.document_status"); //单据类型
    this.getStatusValList("finance_in_record_v1.writeoff_status"); //核销状态
    this.getStatusValList("finance_assetaccount_v1.account_status"); //资金账户类型 */
  },
  mounted() {
    const that = this;
    // that.fetTableHeight();
    this.getData();

    // window.onresize = () => {
    //   return (() => {
    //     that.fetTableHeight();
    //   })();
    // };
  },
  watch: {},
  methods: {
    //催款
    Urge() {
      let arr = this.changList || [];
      console.log(arr);
      if (arr.length < 1) {
        this.$message.warning("请至少选择一条数据");
        return;
      } else {
        this.$confirm("是否确定发送催款信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let idList = new Array();
          for (var i = 0; i < arr.length; i++) {
            idList.push(arr[i].userId);
          }
          let param = new Object();
          param.userId = idList;
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.memberPressForMoney(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "催款发起成功");
              this.getData();
            }
          });
        });
      }
    },
    // 详情按钮
    getDetails(row) {
      console.log(row);
      this.mbName = row.nickname + " (" + row.memberId + ")";
      this.financeInMemberBalanceDetail(row.userId);
    },
    // 应收会员余额详情
    financeInMemberBalanceDetail(id) {
      // let id = "";
      let params = {
        pageStart: this.pageNum_d,
        pageTotal: this.size_d,
        userId: id,
      };
      Api.financeInMemberBalanceDetail(params).then((res) => {
        let BalanceDetailList = res.data.result.data || [];
        this.BalanceDetailList = BalanceDetailList;
        this.total_d = res.data.result.pageCount;
        if (BalanceDetailList.length > 0) {
          this.memberMoney = BalanceDetailList[0].curAmount || 0;
        }
        this.show_Details = true;
      });
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    //确认收款弹窗关闭
    closeDiawriteOff() {
      this.formLabelAlign = {
        amountCollected: "", //收款金额
        accountStatus: "", //账户类型
        accountId: "", //资金账户id
        ids: [], //应收计费id数组
      };
    },
    //账单详情弹窗关闭
    closeDiaDetails() {},
    //资金账户下拉列表
    getCapitalAccountList() {
      Api.capitalAccountList().then((res) => {
        if (res.data.status == "success") {
          let capitalAccountList = res.data.result || [];
          for (var i = 0; i < capitalAccountList.length; i++) {
            capitalAccountList[i].showTitle =
              capitalAccountList[i].accountName +
              " " +
              capitalAccountList[i].account +
              " ";
            for (var j = 0; j < this.account_status_list.length; j++) {
              if (
                capitalAccountList[i].accountStatus ==
                this.account_status_list[j].statusVal
              ) {
                let staVal = this.account_status_list[j].showName;
                capitalAccountList[i].showTitle += staVal;
              }
            }
          }
          this.capitalAccountList = capitalAccountList;
        }
      });
    },
    //选择资金账户
    changeAccountId(e) {
      ////console.log(e);
      let id = e;
      let arrs = this.capitalAccountList || [];
      for (var i = 0; i < arrs.length; i++) {
        if (arrs[i].accountId == id) {
          this.formLabelAlign.accountStatus = arrs[i].accountStatus;
        }
      }
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 审核状态
        if (status == "finance_in_record_v1.approve_status") {
          let dataArr = res.data.result || [];
          this.approveStatusList = dataArr;
        }
        // 结算对象类型
        if (status == "finance_in_record_v1.object_status") {
          let objectStatusList = res.data.result || [];
          this.objectStatusList = objectStatusList;
        }
        // 单价类型
        if (status == "finance_in_record_v1.document_status") {
          let documentStatusList = res.data.result || [];
          this.documentStatusList = documentStatusList;
        }
        // 核销状态
        if (status == "finance_in_record_v1.writeoff_status") {
          let writeoffStatusList = res.data.result || [];
          this.writeoffStatusList = writeoffStatusList;
        }
        // 资金账户类型
        if (status == "finance_assetaccount_v1.account_status") {
          let account_status_list = res.data.result || [];
          this.account_status_list = account_status_list;
        }
      });
    },
    // //获取仓库下拉列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        //////console.log(res.data.result)
        let cangkuList = res.data.result || [];
        this.WarehouseList = cangkuList;
      });
    },
    //确定核销
    saveWriteOff() {
      let param = this.formLabelAlign;
      // param.ids = param.ids;
      ////console.log(param);
      Api.writeOff(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.show_writeOff = false;
          this.getData();
        }
      });
    },
    // 核销按钮
    writeOff() {
      let arr = this.changList || [];
      if (arr.length < 1) {
        this.$message.warning("至少选择一条数据");
        return;
      }
      var idList = [];
      var count = 0;
      for (var i = 0; i < arr.length; i++) {
        idList.push(arr[i].financeInId);
        count = mathjs.add(count, arr[i].settlementAmount); //精度计算
      }
      this.getCapitalAccountList(); //获取资金账户下拉列表
      this.formLabelAlign.ids = idList;
      this.formLabelAlign.amountCollected = count;
      this.show_writeOff = true;
    },
    //返核销
    cancelWriteOff() {
      let arr = this.changList || [];
      if (arr.length < 1) {
        this.$message.warning("至少选择一条数据");
        return;
      }
      var idList = [];
      for (var i = 0; i < arr.length; i++) {
        idList.push(arr[i].financeInId);
      }
      this.$confirm("是否要返核销" + arr.length + " 条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Api.cancelWriteOff({
            ids: idList,
          }).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "应收会员余额列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 20) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      //   let formData = this.queryData;
      let that = this;
      let dN = this.queryData.documentNumber;
      if (dN) {
        dN = tools.getOrderNum(dN);
        ////console.log(dN);
        dN = JSON.stringify(dN);
      }
      let endTimes = "";
      let startTimes = "";
      if (that.queryData.writeoffEnd) {
        that.queryData.endTimes = that.queryData.writeoffEnd + " " + "23:59:59";
      }
      if (that.queryData.writeoffStart) {
        that.queryData.startTimes =
          that.queryData.writeoffStart + " " + "00:00:00";
      }
      let formData = that.queryData;
      formData.pageStart = that.pageNum; //页码
      formData.pageTotal = that.size; //每页条数
      Api.financeInMemberBalanceList(formData).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;

          this.fetTableHeight();
        }
      });
    },
    //
    sortMethod(x) {
      // console.log(x)
      //console.log(val2)
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        writeoffEnd: null, //核销结束时间
        writeoffStart: null, //核销开始时间
        documentNumber: null, //单据编号
        documentStatus: null, //单据类型
        memberId: null, //会员号
        objectStatus: null, //结算对象类型
        storageId: null,
        writeoffStatus: "wei_he_xiao:writeoff_status",
      };
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      var arr = [];
      this.changList = e;
      for (var i = 0; i < e.length; i++) {
        arr.push(e[i].financeInId);
      }
      this.ids = arr;
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },

    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    //分页(详情)
    handleSizeChange_d(val) {
      this.size_d = val;
      this.getData();
    },
    handleCurrentChange_d(val) {
      this.pageNum_d = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.saveBtn {
  margin: 0 auto;
  background-color: #f0a261;
  color: #fff;
  padding: 10px 20px;
}
.detailsBtns {
  width: 78px;
  text-align: center;
  color: #2c6ae1;
  display: block !important;
  cursor: pointer;
}
.topTipsstyle {
  display: flex;
  align-items: center;
}
</style>
